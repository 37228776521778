import React, {FunctionComponent} from "react";
import {LandingContent} from "../domain/content-response";
import PageProps from "../domain/page-props";
import {ButtonStyleLink, Content, SubHeader, Title} from "../components/typography/typography";
import styled from 'styled-components';
import {SecondaryButtonLink} from "../components/button/button";

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;  
  margin: 80px 0 0;
  position: relative;
  color: ${({ theme }) => theme.white};
`;

const color = (theme: any, index: number): string => {
  if (index === 0) return theme.signature;
  if (index === 1) return theme.counter_signature;
  if (index === 2) return theme.counter_signature;
  return theme.signature;
}

const NetworkLinksOverlay = styled.div<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: ${({ theme, index }) => index % 2 > 0 ? theme.counter_signature : theme.signature };
  background-color: ${({ theme, index }) => color(theme, index)};
  opacity: 0.5;
  z-index: 2;
`;

const LinkContainer = styled.div`  
  height: 400px;  
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  padding: 30px 60px;
  color: ${({ theme }) => theme.white};
  &:hover {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;

const ImageContainer = styled.div`  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;  
`;

const TopHeader = styled(Content)`
  font-weight: normal;
  font-size: 16px;
  position: relative;
  z-index: 5;
`;

const NetworksContent = styled.div`  
  position: relative;
  width: 100%;
  z-index: 10;
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const MagCol = styled.div`
  min-height: 400px;  
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 60px;
  color: ${({ theme }) => theme.black};
  
  @media all and (min-width: 968px) {
    min-height: auto;
    height: 715px;  
  }
`;

const Landing: FunctionComponent<PageProps<LandingContent>> = ({content}) => {
  const {landingTitle, landingDescription, networkLinks, magazinesCoverImage, magazinesHeader, magazinesDescription, magazineLink} = content;
  return <>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Title className={'animate__animated animate__fadeInDown'}>{landingTitle}</Title>
        </div>
        <div className={'col-md-12 animate__animated animate__fadeInDown'}
             dangerouslySetInnerHTML={{__html: landingDescription}} />
      </div>
    </div>

    <ColumnContainer className={'row'}>
      {networkLinks.map((link, index) => (
        <LinkContainer key={index} className={`col-xs-12 col-lg-${Math.floor(12 / networkLinks.length * 2)} animate__animated animate__fadeIn animate__delay-1s`}>
          <NetworkLinksOverlay index={index} />
          <ImageContainer style={{backgroundImage: `url("${link.networkLinkImage?._url}")`}} />
          <NetworksContent>
            <div>
              <TopHeader>{link.networkLinkSubheader}</TopHeader>
              <SubHeader style={{color: 'white', position: 'relative', zIndex: 5}}>{link.networkLinkTitle}</SubHeader>
            </div>
            <RightCol>
              {link.networkUrl?._url && <ButtonStyleLink url={link.networkUrl?._url}>Læs mere</ButtonStyleLink>}
            </RightCol>
          </NetworksContent>
        </LinkContainer>
      ))}
    </ColumnContainer>

    {/*{magazineLink && <ColumnContainer style={{margin: '0'}} className={'animate__animated animate__fadeIn animate__delay-2s row'}>
      <MagCol className={'col-lg-6'}>
        <a href={magazineLink?._url} style={{ maxHeight: '100%', maxWidth: '100%' }}>
          <Image src={magazinesCoverImage?._url} />
        </a>
      </MagCol>
      <MagCol className={'col-lg-6'}>
        <div>
          <Title>
            {magazinesHeader}

          </Title>
          <Content dangerouslySetInnerHTML={{__html: magazinesDescription}} />
          <div style={{textAlign: 'right', marginTop: '45px'}}>
            <SecondaryButtonLink to={magazineLink?._url}>Læs mere</SecondaryButtonLink>
          </div>
        </div>
      </MagCol>
    </ColumnContainer>}*/}
  </>;
}

export default Landing;
