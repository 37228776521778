"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var theme = {
    counter_signature: "#FFBF50",
    grey: "#ACACAC",
    dark_grey: "#707070",
    light_grey: "#F4F4F4",
    signature: "#EA2190",
    dark_signature: "#BE327E",
    black: '#000000',
    white: '#FFFFFF'
};
exports.default = theme;
